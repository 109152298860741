export enum TableName {
  GENERAL = 'General',
  PRODUCTION = 'Production',
  BIRTH = 'Birth',
  EFFICIENCY = 'Efficiency',
  EXTERIORABOVE = 'ExteriorAbove',
  EXTERIORBELOW = 'ExteriorBelow',
  HEALTH = 'Health',
  FUNCTIONAL = 'Functional',
}

export enum Device {
  MOBILEMAX = 767,
  TABLETMAX = 767,
  DESKTOPSMAX = 1199,
  DESKTOPMMAX = 1439,
  DESKTOPLMAX = 5000,
}

export enum BullDetail {
    NVI = 'dutchFlemishIndex',
    INET = 'inet',
    MILK = 'milkKilograms',
    PROTEIN = 'percentageProtein',
    FAT = 'percentageFat',
    BVK = 'foodCostSaving',
    LIFESPAN = 'Lifespan',
    UDDER_HEALTH = 'udderHealth',
    FERTILITY = 'fertility',
    CLAW_HEALTH = 'clawHealth',
    BIRTH_INDEX = 'birthIndex',
    BIRTH_EASE = 'birthEase',
    TOTAL = 'totalExterior',
    UDDER = 'udder',
    LEGWORK = 'legwork',
    LIFERANGE = 'LifeSpan',
    DRYMATTER = 'DryMatterIntake',
    FOODCOSTMAIN = 'FoodCostSavingMaintenance',
    FOODCOST = 'FoodCostSaving',
    UREUM = 'Ureum',
    BODYWEIGHT = 'BodyWeight',
    LIFEPRODINDEX = 'LifeProductionIndex',
    LIFEPRODMILK = 'LifeProductionMilk',
    LIFEPRODFAT = 'LifeProductionFat',
    LIFEPRODLACT = 'LifeProductionLactose',
    ADVANCE = 'Advance',
    CONTENT = 'Content',
    OPEN = 'Openness',
    CONDITIONSCORE = 'ConditionScore',
    CROSSWIDTH = 'CrossWidth',
    CLAWANGLE = 'ClawAngle',
    FRONTUDDERATTA = 'FrontUdderAttachment',
    FRONTTREAT = 'FrontTeatPosition',
    TEATLENGTH = 'TeatLength',
    STIFLE = 'Stifle',
    BACKTEAT = 'BackTeatPosition',
    RACE = "breed",
    SOURCE = "sourceBreedingValues",
    SOURCEEVA = "sourceOfEvaluation",
    COLOR = "hairColor",
    TRIPLE = "tripleACode",
    INHERITED = "inheritedFactors",
    HEIGHT = "Height",
    CROSSPOS = "CrossPosition",
    LEGPOSBACK = "LegPositionBack",
    LEGPOSSIDE = "LegPositionSide",
    FRONTLEGPOS = "FrontLegPosition",
    LEGUSE = "LegUse",
    UDDERDEPTH = "UdderDepth",
    UDDERBALANCE = "UdderBalance",
    BACKUDDERHEIGHT = "BackUdderHeight",
}

export enum HeaderValues {
  FW= 'FW',
  BTBH = '%btbh',
  PROD= 'production',
  MEASURE = 'measurement',
  ADDVAL= 'additionalValue',
  VALUE = 'value',
  GENERAL = 'general',
  EMPTY = 'empty',
  BAR = 'bar',
  BARSUBTEXTLEFT = 'barSubTextLeft',
  BARSUBTEXTRIGHT = 'barSubTextRight',
  DAUGHTERS = 'daughters',
  COMPANIES = 'companies',
  SOURCE = 'source',
}