<template>
    <v-card elevation="6" v-on="{ click: isClickable ? handleClick : null }">
        <v-row class="px-4 pb-4 pt-2" :class=getClickableClass>
            <v-col>
                <p>
                    <b>{{ formatCardHeaderName(pedigreeBull?.shortName, pedigreeBull?.fullName) }}</b>
                    <v-icon v-if="isSire"
                        icon="$genderMale"
                        color="#2f679c"
                    />
                    <v-icon v-else
                        icon="$genderFemale"
                        color="#b74d6d"
                    />
                </p>
                <p>
                    {{ (pedigreeBull?.fullName) }}
                </p>
            </v-col>
            <v-col cols="2" class="mt-3 d-flex justify-end" v-if="isClickable">
                <v-icon
                    icon="$chevronRight"
                    color="#7F746F"
                />
            </v-col>
        </v-row>
        <v-row class="px-4 mt-n6 pb-4" v-if="type === 'child'">
            <v-col>
                <p class="pedigree__card__row__static">{{ $t('details.summary.first.artificialInseminationNumber') }}</p>
                <p>{{ formatEmptyToDash(pedigreeBull?.artificialInseminationNumber) }}</p>
            </v-col>
        </v-row>
        <v-row class="px-4 mt-n6 pb-4">
            <v-col>
                <p class="pedigree__card__row__static">{{ $t('details.summary.first.interbullNumber') }}</p>
                <p>{{ formatEmptyToDash(pedigreeBull?.interbullNumber) }}</p>
            </v-col>
        </v-row>
        <v-row class="px-4 mt-n6 pb-4">
            <v-col>
                <p class="pedigree__card__row__static">{{ $t('details.summary.first.dateOfBirthSmall') }}</p>
                <p>{{ formatDate(pedigreeBull?.dateOfBirth) }}</p>
            </v-col>
        </v-row>
        <v-row class="px-4 mt-n6 pb-4" v-if="type === 'child' || type === 'parents'">
            <v-col>
                <p class="pedigree__card__row__static">{{ $t('details.summary.first.raceInfo') }}</p>
                <p>{{ formatEmptyToDash(pedigreeBull?.breed) }}</p>
            </v-col>
        </v-row>
        <v-row class="px-4 mt-n6 pb-4" v-if="type === 'child'">
            <v-col>
                <p class="pedigree__card__row__static">{{ $t('details.summary.first.inheritedFactors') }}</p>
                <p>{{ formatValueOrDash(formatInheritedFactors(pedigreeBull?.inheritedFactors)) }}</p>
            </v-col>
        </v-row>
        <v-row class="px-4 mt-n6 pb-4" v-if="type === 'child'">
            <v-col>
                <p class="pedigree__card__row__static">{{ $t('details.summary.first.inbreedingCoefficient') }}</p>
                <p>{{ formatInbreedingCoefficient(pedigreeBull?.inbreedingCoefficient) }}</p>
            </v-col>
        </v-row>
        <v-row class="px-4 mt-n6 pb-4" v-if="type === 'child'">
            <v-col>
                <p class="pedigree__card__row__static">{{ $t('details.summary.first.kinshipRate') }}</p>
                <p>{{ formatEmptyToDash(pedigreeBull?.kinshipRate) }}</p>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { formatValueOrDash } from '../scripts/format-value-or-dash';
import { formatDateMixin } from '../mixins/format-date';
import { Device } from '../models/enums';
import { formatLocale } from "../scripts/format-locale";
import {formatInheritedFactors} from "../scripts/format-inherited-factors";
import {formatInbreedingCoefficient} from "../scripts/format-inbreeding-coefficient";

export default defineComponent({
    name: 'PedigreeCardView',
    mixins: [formatDateMixin],
    props: {
        isSire: {
            type: Boolean,
        },
        type: {
            type: String,
        },
        pedigreeBull: {
            type: Object
        },
        typeParents: {
            type: String
        },
        isClickable: {
            type: Boolean,
            default: false
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    emits: ["clicked"],
    data() {
        return {
            windowWidth: 0,
        };
    },
    methods: {
      formatValueOrDash,
      formatInbreedingCoefficient,
      formatInheritedFactors,
        FormatLocale(value: string) {
            return formatLocale(value)
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        handleClick() {
            if (this.pedigreeBull) {
               this.$emit('clicked', this.pedigreeBull.interbullNumber);
            }
        },
        formatEmptyToDash(value: number | string) {
            return formatValueOrDash(value);
        },
        formatCardHeaderName(fullName: string, shortName: string){
            if (fullName) { 
                return fullName
            }

            else if (shortName) { 
                return shortName
            }

            else {
                return this.$t('details.unknown')
            }
        },
        formatCardSubHeaderName(fullName: string, shortName: string){
            if (shortName) { 
                return shortName
            }
            
            else if (fullName) { 
                return fullName
            }

            else {
                return this.$t('details.unknown')
            }
        },
        getClickableClass() {
            return this.isSire ? "clickable" : "";
        },
        getTypeText(): string {
            switch (this.typeParents) {
                case 'SIRE':
                    return this.$t('pedigree.father')
                case 'DAM':
                    return this.$t('pedigree.mother')
                case 'SS':
                    return this.$t('pedigree.fatherfather')
                case 'SD':
                    return this.$t('pedigree.fathermother')
                case 'DS':
                    return this.$t('pedigree.motherfather')
                case 'DD':
                    return this.$t('pedigree.mothermother')
                case 'SSS':
                    return this.$t('pedigree.vvv')
                case 'SSD':
                    return this.$t('pedigree.mvv')
                case 'SDS':
                    return this.$t('pedigree.vmv')
                case 'SDD':
                    return this.$t('pedigree.mmv')
                case 'DSS':
                    return this.$t('pedigree.vvm')
                case 'DSD':
                    return this.$t('pedigree.mvm')
                case 'DDS':
                    return this.$t('pedigree.vmm')
                case 'DDD':
                    return this.$t('pedigree.mmm')
                default:
                    return ''
            }
        }
    },
    setup() {
        return {
            Device
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
});
</script>