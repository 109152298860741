<template>
    <div class="pedigree">
        <div class="pedigree__container">
            <div v-if="windowWidth < Device.MOBILEMAX" class="pedigree__switch">
                <div>
                    <v-btn v-if="getPedigreeState > 0" @click="onClickPreviousPedigree" variant="text" color="#2f679c"
                        type="link"><span class="button-content">
                            <v-icon
                                icon="$chevronLeft"
                                color="#7F746F"
                            />
                        </span>{{ getLeftSwitchText() }}
                    </v-btn>
                </div>
                <div>
                    <v-btn v-if="getPedigreeState < 3" @click.prevent="onClickNextPedigree" variant="text" color="#2f679c"
                        type="link"><span class="button-content">
                        </span>{{ getRightSwitchText() }}
                        <v-icon
                            icon="$chevronRight"
                            color="#7F746F"
                        />
                    </v-btn>
                </div>
            </div>
            <div v-if="(getPedigreeState === 0 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card">
                <div class="parentBull__root">
                    <PedigreeCardView 
                        :pedigree-bull="getPedigreeBull" 
                        is-sire
                        type="child"
                    />
                </div>
            </div>

            <div v-if="(getPedigreeState === 1 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card parentBull">
                <div class="parentBull__sire container">
                    <PedigreeCardView 
                        :pedigree-bull="pedigreeBullRelation('SIRE')"
                        is-sire
                        type="parents"
                        type-parents="SIRE"
                        :is-clickable="isClickable('SIRE')"
                        @clicked="onClickSire"
                    />
                </div>
                <div class="parentBull__cow container">
                    <PedigreeCardView 
                        :pedigree-bull="pedigreeBullRelation('DAM')"
                        type="parents"
                        type-parents="DAM"
                    />
                </div>
            </div>
            <div v-if="(getPedigreeState === 2 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card">
                <div class="parentBull">
                    <div class="parentBull__sire grandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_SIRE')"
                            is-sire
                            type="parents"
                            type-parents="SS"
                            :is-clickable="isClickable('PATERNAL_GRAND_SIRE')"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow grandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_DAM')"
                            type="parents"
                            type-parents="SD" 
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire grandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_SIRE')"
                            is-sire 
                            type="parents"
                            type-parents="DS"
                            :is-clickable="isClickable('MATERNAL_GRAND_SIRE')"
                            @clicked="onClickSire"
                        /> 
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow grandparentsBull">
                        <PedigreeCardView
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_DAM')"
                            type="parents"
                            type-parents="DD"
                        />
                    </div>
                </div>
            </div>
            <div v-if="(getPedigreeState === 3 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card">
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_SIRES_SIRE')"
                            is-sire 
                            type-parents="SSS"
                            :is-clickable="isClickable('PATERNAL_GRAND_SIRES_SIRE')"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_SIRES_DAM')"
                            type-parents="SSD"
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_DAMS_SIRE')"
                            is-sire 
                            type-parents="SDS"
                            :is-clickable="isClickable('PATERNAL_GRAND_DAMS_SIRE')"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_DAMS_DAM')"
                            type-parents="SDD"
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_SIRES_SIRE')"
                            is-sire
                            type-parents="DSS"
                            :is-clickable="isClickable('MATERNAL_GRAND_SIRES_SIRE')"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_SIRES_DAM')"
                            type-parents="DSD"
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_DAMS_SIRE')"
                            is-sire
                            type-parents="DDS"
                            :is-clickable="isClickable('MATERNAL_GRAND_DAMS_SIRE')"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_DAMS_DAM')"
                            type-parents="DDD"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import PedigreeCardView from '../components/PedigreeCardView.vue'
import { BullService } from "../services/BullService"
import { BullInfo } from "@/models/bullinfo"
import { Device } from '../models/enums'

const bullService = new BullService();

export default defineComponent({
    name: 'PedigreeView',
    components: {
        PedigreeCardView,
    },
    data() {
        return {
            windowWidth: 0,
            bullInfo: {} as BullInfo,
            siresMap : {
                SIRE: false,
                PATERNAL_GRAND_SIRE: false,
                MATERNAL_GRAND_SIRE: false,
                PATERNAL_GRAND_SIRES_SIRE: false,
                PATERNAL_GRAND_DAMS_SIRE: false,
                MATERNAL_GRAND_SIRES_SIRE: false,
                MATERNAL_GRAND_DAMS_SIRE: false,
            } as Record<string, boolean>,
        };
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },

    watch: {
        '$route.params': {
            handler(newParams) {
                const referenceBase = newParams.base || this.getPedigreeBull?.referenceBase;
                const calculationType = newParams.calculationType || this.getPedigreeBull?.calculationType;
                this.setBaseColor(referenceBase);
                this.setCalcType(calculationType);
            },
            immediate: true,
        },
        getPedigreeBull: {
            handler() {
                this.deriveClickableSires()
            },
            immediate: true,
        }
    },

    computed: {
        ...mapGetters({
            getPedigreeState: 'getPedigreeState',
            getPedigreeBull: "getPedigreeBull",
            bullDetail: "getBullDetail",
            getCalcType: "getCalcType",
            getBaseColor: "getBaseColor",
        }),
    },

    methods: {
        ...mapMutations({
            setPedigreeState: "setPedigreeState",
            setPedigreeBull: "setPedigreeBull",
            setLayout: "setLayout",
            setCalcType: "setCalcType",
            setBaseColor: "setBaseColor",
        }),

        async onClickSire(value: string) {
            const interbullNumber = value;
            let sireInfo: any
            try {
                sireInfo = await bullService.getSire(interbullNumber);
            }
            catch (error) {
                console.log('No sireInfo found.')
            } finally {
                if (sireInfo && Array.isArray(sireInfo) && sireInfo.length > 0) {
                    // first sire found based on interbullNumber
                    const referenceBase = sireInfo[0]?.referenceBase;
                    const calculationType = sireInfo[0]?.calculationType;
              
                    // this.setPedigreeBull(sireInfo[0])
                    this.setPedigreeState(1)

                    this.setBaseColor(referenceBase)
                    this.setCalcType(calculationType)

                    this.$router.push({
                        name: 'bull-detail',
                        params: {
                            ...this.$route.params,
                            interbullNumber: interbullNumber,
                            base: referenceBase,
                            calculationType: calculationType,
                            tabParam: 'Pedigree'
                        },
                    });
                }
            }
        },

        onClickNextPedigree() {
            this.setPedigreeState(this.getPedigreeState + 1)
        },

        onClickPreviousPedigree() {
            this.setPedigreeState(this.getPedigreeState - 1)
        },

        handleResize() {
            this.windowWidth = window.innerWidth;
        },

        getLeftSwitchText(): string {
            switch (this.getPedigreeState) {
                case 0:
                    return this.$t('pedigree.first-generation')
                case 1:
                    return this.$t('pedigree.first-generation')
                case 2:
                    return this.$t('pedigree.second-generation')
                case 3:
                    return this.$t('pedigree.third-generation')
                default:
                    return this.$t('pedigree.first-generation')
            }
        },

        getRightSwitchText(): string {
            switch (this.getPedigreeState) {
                case 0:
                    return this.$t('pedigree.second-generation')
                case 1:
                    return this.$t('pedigree.third-generation')
                case 2:
                    return this.$t('pedigree.fourth-generation')
                case 3:
                    return this.$t('pedigree.fourth-generation')
                default:
                    return this.$t('pedigree.third-generation')
            }
        },

        pedigreeBullRelation(relation: string) {
            return this.getPedigreeBull?.pedigree.find((item) => item.relation === relation )?.identification || {}
        },

        async deriveClickableSires() {
            const keys = Object.keys(this.siresMap)
            keys.map((key) => this.getPedigreeBull?.pedigree.find((item) => item.relation === key)?.identification.interbullNumber)
            const promises = keys.map((key) => {
                const interbullNumber = this.getPedigreeBull?.pedigree.find((item) => item.relation === key)?.identification.interbullNumber
                if (interbullNumber) {
                    return bullService.getSire(interbullNumber);
                }
                return Promise.resolve(null)
            })
            const results = await Promise.allSettled(promises) as unknown as PromiseSettledResult<BullInfo[]>
            this.siresMap = Object.fromEntries(
                keys.map((key, index) => [key, results[index].status === 'fulfilled' && 'value' in results[index] && !!results[index].value && !!results[index].value?.length])
            )
        },

        isClickable(relation: string) {
            return this.siresMap[relation]
        }
    },

    setup() {
        return {
            Device,
        }
    },

    async mounted() {
        try {
            const pedigree: BullInfo = await bullService.getForPedigreeView(this.bullDetail.primaryKey, this.getBaseColor, this.getCalcType)
            this.setPedigreeBull(pedigree)
        }
        catch (error) {
            return
        }
    },

    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
});
</script>

<style lang="scss" scoped>
//TODO: copied from pedigree.scss, needs to import variables from ...
// Total styling seems massive, could probably be reduced.

.pedigree {
  margin: 0 16px;
  overflow: auto;
  @include mobile {
    margin-left: 0;
    padding: 0 $horizontal-spacing-single;;
  }

  &__container {
    align-items: center;
    display: flex;
    @include mobile {
      flex-direction: column;
    }
  }
  &__card {
    width: 250px;
    @include mobile {
      margin-left: 0;
      width: 100%;
    }
    position: relative;
    margin-left: 2rem;
    &__row {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: $font-size-s;
      padding: $vertical-spacing-half;
      align-self: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      svg {
        align-self: flex-start;
      }
      &__header {
        justify-content: space-between;
        display: flex;
        width: 100%;
        font-size: $font-size-m;
        font-weight: 600;
      }
      &__sub {
        align-self: flex-start;
        font-size: $font-size-m;
        text-align: left;
      }
      &__static {
        color: $base-gray-80;
      }
    }
  }

  &__type {
    font-weight: 400;
    font-size: 14px;
    color: $base-gray-80;
      display: flex;
  }
  &__name {
      display: flex;
      text-align: left;
      gap: $vertical-spacing-half;
      
      &__gender {
        min-width: 24px;
      }
    }

  &__switch {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: $vertical-spacing-half;
  }
}

.parentBull {
  position: relative;
  &__cow,
  &__sire {
    position: relative;
  }
}

.parentBull::before {
  border-top: 2px solid #e4e0de;
  content: "";
  @include mobile {
    content: none;
  }
  left: -2rem;
  position: absolute;
  top: 50%;
  width: 1rem;
}

.parentBull__sire .v-card.clickable {
  cursor: pointer;
}

.parentBull__sire::before {
  border-left: 2px solid #e4e0de;
  content: "";
  @include mobile {
    content: none;
  }
  height: 50%;
  top: 50%;
  left: -1rem;
  position: absolute;
}

.parentBull__sire::after {
  border-top: 2px solid #e4e0de;
  content: "";
  @include mobile {
    content: none;
  }
  position: absolute;
  top: 50%;
  left: -1rem;
  width: 1rem;
}

.parentBull__cow::before {
  border-left: 2px solid #e4e0de;
  content: "";
  @include mobile {
    content: none;
  }
  height: 50%;
  top: 0;
  left: -1rem;
  position: absolute;
}

.parentBull__cow::after {
  border-top: 2px solid #e4e0de;
  content: "";
  @include mobile {
    content: none;
  }
  position: absolute;
  top: 50%;
  left: -1rem;
  width: 1rem;
}

.grandparentsBull {
  padding: 3.3rem 0;
  @include mobile {
    padding: 0 0 $horizontal-spacing-double 0;
  }
}

.greatGrandparentsBull {
  padding: 0.6rem 0;
  margin-right: 8px
}

.container {
  padding: 13rem 0;
  @include mobile {
    padding: 0 0 $horizontal-spacing-double 0;
  }
  position: relative;
}

.parentBull__root svg.gender-icon,
.parentBull__sire svg.gender-icon {
  fill: $base-blue-80;
}

.parentBull__cow svg.gender-icon {
  fill: $base-red-90;
}

.button-content {
  display: flex;
}


</style>
