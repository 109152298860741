<template>
    <v-container fluid class="pl-0 pr-0">
        <v-row>
            <v-col>
                <h2 class="pl-md-0 pl-3">{{ $t("details.production.header") }}</h2>
                <ProductionTable
                    :header-rows="productionColumns"
                    :body-rows="mapProductionBreedingValues(lactationInfoList)"
                    :footer-rows="mapFooterRowLactation(lactationInfoList)" 
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h2 class="pl-md-0 pl-3"> {{ $t("details.efficiency.header") }}</h2>
                <BreedingGeneralTable 
                    hide-header is-efficiency
                    :body-rows="mapGeneralBreedingValues(efficiencyBreedingValueGroupList[0].breedingValueList, efficiencyBreedingValueGroupList[0].tagName)"
                    :header-rows="customColumns" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h2 class="pl-md-0 pl-3">{{ $t("details.health.header") }}</h2>
                <BreedingGeneralTable
                    :body-rows="mapGeneralBreedingValues(healthBreedingValueGroupList[0].breedingValueList, healthBreedingValueGroupList[0].tagName)"
                    :header-rows="customColumns" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h2 class="pl-md-0 pl-3">{{ $t("details.birth.header") }}</h2>
                <BreedingGeneralTable
                    :body-rows="mapGeneralBreedingValues(birthBreedingValueGroupList[0].breedingValueList, birthBreedingValueGroupList[0].tagName)"
                    :header-rows="customColumns" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h2 class="pl-md-0 pl-3">{{ $t("details.exteriorabove.header") }}</h2>
                <BreedingGeneralTable
                    :body-rows="mapGeneralBreedingValues(exteriorAboveBreedingValueGroupList[0].breedingValueList, exteriorAboveBreedingValueGroupList[0].tagName)"
                    :header-rows="customColumns" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h2 class="pl-md-0 pl-3">{{ $t("details.exteriorbelow.header") }}</h2>
                <BreedingGeneralTable
                    :body-rows="mapGeneralBreedingValues(exteriorBelowBreedingValueGroupList[0].breedingValueList, exteriorBelowBreedingValueGroupList[0].tagName)"
                    :header-rows="customColumns" 
                    withSubText
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h2 class="pl-md-0 pl-3">{{ $t("details.functional.header") }}</h2>
                <BreedingGeneralTable
                    :body-rows="mapGeneralBreedingValues(functionalBreedingValueGroupList[0].breedingValueList, functionalBreedingValueGroupList[0].tagName)"
                    :header-rows="customColumns" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h2 class="pl-md-0 pl-3">{{ $t("details.daughters.header") }}</h2>
                <v-row>
                    <v-col>
                        <DaughtersGeneralTable
                            :header="$t('details.daughters.productionInheritance.header')"
                            :header-rows="daughterInfoColumns"
                            :body-rows="mapDaughterInfoRows(productionInheritanceList)"
                        />
                    </v-col>
                    <v-col>
                        <DaughtersGeneralTable
                            :header="$t('details.daughters.companyInspection.header')"
                            :header-rows="daughterInfoColumns"
                            :body-rows="mapDaughterInfoRows(companyInspectionList)"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, toRaw } from 'vue';
import ProductionTable from '@/components/ProductionTable.vue';
import BreedingGeneralTable from '@/components/BreedingGeneralTable.vue';
import DaughtersGeneralTable from '@/components/DaughtersGeneralTable.vue';
import { BreedingValue, BreedingValueGroup, BreedingValueLactation, BreedingValueSubValue, LactationInfo, DaughterInfo } from '@/models/bullinfo';
import { formatValueOrDash } from '@/scripts/format-value-or-dash';
import { TableName } from '@/models/enums';
import { useStore } from 'vuex';
import { formatNumber } from '@/scripts/format-number';
import { ProdColumnsBreeding, CustomColumnsBreeding, DaughterInfoColumns } from "../models/table-headers";
import {displayDecimals} from "@/scripts/display-numbers-decimals";
import orderBy from 'lodash/orderBy';


export default defineComponent({
    name: 'BreedingValuesView',
    components: {
        ProductionTable,
        BreedingGeneralTable,
        DaughtersGeneralTable,
    },
    data() {
        return {
            customColumns: [
                {
                    id: 'general-column',
                    cells: CustomColumnsBreeding
                },
            ],
            productionColumns: [
                {
                    id: 'production-column',
                    cells: ProdColumnsBreeding,
                },
            ],
            daughterInfoColumns: [
                {
                    id: 'daughter-info-column',
                    cells: DaughterInfoColumns,
                },
            ],
        };
    },
    setup() {
        const store = useStore();
        const healthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList.filter((item) => item.tagName == TableName.HEALTH);
      });
    const functionalBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList.filter((item) => item.tagName == TableName.FUNCTIONAL);
      });
    const birthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList.filter((item) => item.tagName == TableName.BIRTH);
      });
    const exteriorAboveBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList.filter((item) => item.tagName == TableName.EXTERIORABOVE);
      });

    const exteriorBelowBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList.filter((item) => item.tagName == TableName.EXTERIORBELOW);
      });

    const efficiencyBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList.filter((item) => item.tagName == TableName.EFFICIENCY);
      });

        const lactationInfoList: ComputedRef<LactationInfo[]> = computed(() => {
            return store.state.bullDetail.breedingValueBase?.breedingValueLactationGroup?.lactationInfoList
        })

        const productionInheritanceList: ComputedRef<DaughterInfo[]> = computed(() => {
            return orderBy(store.state.bullDetail.productionInheritance, (item) => +item.numberOfDaughters, 'desc')
        })

        const companyInspectionList: ComputedRef<DaughterInfo[]> = computed(() => {
            return orderBy(store.state.bullDetail.companyInspection, (item) => +item.numberOfDaughters, 'desc')
        })

        return {
            efficiencyBreedingValueGroupList,
            healthBreedingValueGroupList,
            birthBreedingValueGroupList,
            functionalBreedingValueGroupList,
            exteriorAboveBreedingValueGroupList,
            lactationInfoList,
            exteriorBelowBreedingValueGroupList,
            productionInheritanceList,
            companyInspectionList,
        }
    },
    methods: {
        formatEmptyToDash(value: number | string | undefined): number | string | undefined {
            return formatValueOrDash(value);
        },
        formatNumber(value: string): string {
            return formatNumber(value)
        },
        instanceOfSubValue(object: BreedingValue): object is BreedingValueSubValue {
            return 'indentation' in object;
        },
        mapProductionBreedingValues(list: LactationInfo[]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const lactationRowList: Array<BreedingValueLactation | any> = [];

            for (let i = 0; i < list?.length; i++) {
                const PRD_KGM = list[i].breedingValueList.find(bv => bv?.sourceName == "PRD_KGM");
                if (PRD_KGM === undefined) { list[i].breedingValueList[0] = { sourceName: "PRD_KGM", value: "-", sourceOfEvaluation: "" }; }

                const PRD_PV = list[i].breedingValueList.find(bv => bv?.sourceName == "PRD_PV");
                if (PRD_PV === undefined) { list[i].breedingValueList[1] = { sourceName: "PRD_PV", value: "-", sourceOfEvaluation: "" }; }

                const PRD_PE = list[i].breedingValueList.find(bv => bv?.sourceName == "PRD_PE");
                if (PRD_PE === undefined) { list[i].breedingValueList[2] = { sourceName: "PRD_PE", value: "-", sourceOfEvaluation: "" }; }

                const PRD_PL = list[i].breedingValueList.find(bv => bv?.sourceName == "PRD_PL");
                if (PRD_PL === undefined) { list[i].breedingValueList[3] = { sourceName: "PRD_PL", value: "-", sourceOfEvaluation: "" }; }

                const PRD_KGV = list[i].breedingValueList.find(bv => bv?.sourceName == "PRD_KGV");
                if (PRD_KGV === undefined) { list[i].breedingValueList[4] = { sourceName: "PRD_KGV", value: "-", sourceOfEvaluation: "" }; }

                const PRD_KGE = list[i].breedingValueList.find(bv => bv?.sourceName == "PRD_KGE");
                if (PRD_KGE === undefined) { list[i].breedingValueList[5] = { sourceName: "PRD_KGE", value: "-", sourceOfEvaluation: "" }; }

                const PRD_KGL = list[i].breedingValueList.find(bv => bv?.sourceName == "PRD_KGL");
                if (PRD_KGL === undefined) { list[i].breedingValueList[6] = { sourceName: "PRD_KGL", value: "-", sourceOfEvaluation: "" }; }

                const PRD_INET = list[i].breedingValueList.find(bv => bv?.sourceName == "PRD_INET");
                if (PRD_INET === undefined) { list[i].breedingValueList[7] = { sourceName: "PRD_INET", value: "-", sourceOfEvaluation: "" }; }

                const URE_URE = list[i].breedingValueList.find(bv => bv?.sourceName == "URE_URE");
                if (URE_URE === undefined) { list[i].breedingValueList[8] = { sourceName: "URE_URE", value: "-", sourceOfEvaluation: "" }; }

                const SCC_CGT = list[i].breedingValueList.find(bv => bv?.sourceName == "SCC_CGT");
                if (SCC_CGT === undefined) { list[i].breedingValueList[9] = { sourceName: "SCC_CGT", value: "-", sourceOfEvaluation: "" }; }

                const btbh = list[i].breedingValueList.find(bv => bv?.sourceName == "tagReliabilityPercentage");
                if (btbh === undefined) { list[i].breedingValueList[10] = { sourceName: "tagReliabilityPercentage", value: "-", sourceOfEvaluation: "" }; }

                const dochters = list[i].breedingValueList.find(bv => bv?.sourceName == "tagDaughters");
                if (dochters === undefined) { list[i].breedingValueList[11] = { sourceName: "tagDaughters", value: "-", sourceOfEvaluation: "" }; }

                const bedrijven = list[i].breedingValueList.find(bv => bv?.sourceName == "tagCompanies");
                if (bedrijven === undefined) { list[i].breedingValueList[12] = { sourceName: "tagCompanies", value: "-", sourceOfEvaluation: "" }; }
            }

            for (let i = 0; i < list?.length; i++) {
                const lactationInfo = list[i];

                const lactationObj = { sourceName: 'Lactation', value: parseInt(lactationInfo.id), sourceOfEvaluation: '' } as BreedingValueLactation

                const sortOrder = ["PRD_KGM", "PRD_PV", "PRD_PE", "PRD_PL", "PRD_KGV", "PRD_KGE", "PRD_KGL", "PRD_INET", "URE_URE", "SCC_CGT", "tagReliabilityPercentage", "tagDaughters", "tagCompanies"];
                lactationInfo.breedingValueList.sort(
                    function (a, b) {                                 // Otherwise,
                        return sortOrder.indexOf(a.sourceName) - sortOrder.indexOf(b.sourceName); // Substract indexes, If element `a` comes first in the array, the returned value will be negative, resulting in it being sorted before `b`, and vice versa.
                    }
                );

                if (lactationInfo.id == "overall") { continue; }

                const newNewArray = [lactationObj, ...lactationInfo.breedingValueList]
                lactationRowList.push(newNewArray)
            }

            

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const toReturn = lactationRowList.map((lactation: BreedingValueLactation | any) => ({
            id: 'production',
            cells: lactation.map((breedingValueLactation: BreedingValueLactation, index: number) => ({
              id: breedingValueLactation.sourceName,
              value: this.clusterCheck(breedingValueLactation, index),
              slotName: 'custom-styled-column',
              sourceCompany: toRaw(lactation[index]).sourceCompany
            }))
          }));

          return toReturn;
        },

        clusterCheck(breedingValueLactation, index) {

            // Percentage fat and percentage lactose cell values need to be displayed with 2 decimals at all times.
            // Urea cell values need to be displayed with 1 decimals at all times.
            const cellIndexPercentageFat = 2;
            const cellIndexPercentageLactose = 4;
            const cellIndexUrea = 9;
            const decimalsTwo = 2;
            const decimalsOne = 1;
            const decimalsZero = 0;

            if (index >= 12 || index <= 1) {
                if (breedingValueLactation.value === 0) {
                    return 0
                } else {
                    return formatValueOrDash(breedingValueLactation.value)
                }
            } else {
                if (breedingValueLactation?.value === null) {
                    return '-'
                }
                else {
                    let decimals
                if (index >= cellIndexPercentageFat && index <= cellIndexPercentageLactose) {
                    decimals = decimalsTwo
                } else if (index === cellIndexUrea) {
                    decimals = decimalsOne
                } else {
                    decimals = decimalsZero
                }
                return displayDecimals(breedingValueLactation.value.toString(), decimals, this.$i18n.locale)
                }
            }
        },

        mapGeneralBreedingValues(list: BreedingValue[], tagName: string) {
            const completeBreedingValues: BreedingValue[] = [];

            for (let index = 0; list.length > index; index++) {
                const breedingValue: BreedingValue = list[index];
                const breedingValueSubValueList = breedingValue.breedingValueSubValueList;
                completeBreedingValues.push(breedingValue)

                if (breedingValueSubValueList === undefined){
                    //
                }

                else{
                    if (breedingValueSubValueList.length > 0) {

                        for (let j = 0; breedingValueSubValueList.length > j; j++) {
                            const subBreedingValue = breedingValueSubValueList[j]
                            subBreedingValue.indentation = 'indentation'

                            completeBreedingValues.push(subBreedingValue)
                        }
                    }
                }
            }

            return completeBreedingValues.map((breedingValue: BreedingValue) => ({
                id: breedingValue.tagName,
                tagName: tagName,
                cells: [
                    {
                        id: breedingValue.tagName,
                        value: this.instanceOfSubValue(toRaw(breedingValue)) &&
                        !breedingValue.tagName.includes("robot") &&
                        !breedingValue.tagName.includes("Recovery") &&
                        !breedingValue.tagName.includes("Stability") &&
                        !breedingValue.tagName.includes("Herstel") &&
                        !breedingValue.tagName.includes("Stabiliteit")
                            ? '\xa0\xa0\xa0' + this.$t(`details.${tagName.toLowerCase()}.${breedingValue.tagName}`) : this.$t(`details.${tagName.toLowerCase()}.${breedingValue.tagName}`),
                        slotName: 'text-left'
                    },
                    {
                        id: 'BarSubTextLeft',
                        value: breedingValue.tagValueLow ? this.$t(`details.sub-tags.${breedingValue.tagValueLow}`) : null,
                        slotName: 'bar-sub-text-left',
                    },
                    {
                        id: 'value',
                        value: breedingValue.value,
                        slotName: 'custom-styled-column',
                        className: "semi-bold",
                    },
                    {
                        id: 'BarSubTextRight',
                        value: breedingValue.tagValueLow ? this.$t(`details.sub-tags.${breedingValue.tagValueHigh}`) : null,
                        slotName: 'bar-sub-text-right',
                    },
                    {
                        id: 'measurement',
                        value: breedingValue,
                        slotName: 'custom-styled-column-3',
                    },
                    {
                        id: '%btbh',
                        value: breedingValue.reliabilityPercentage === 0 ? 0 : formatValueOrDash(breedingValue.reliabilityPercentage),
                        horizontalAlignment: "center",
                    },
                    {
                        id: 'daughters',
                        value: this.formatEmptyToDash(breedingValue.amountDaughters),
                        horizontalAlignment: "center",
                    },
                    {
                        id: 'companies',
                        value: this.formatEmptyToDash(breedingValue.amountCompanies),
                        horizontalAlignment: "center",
                    },
                    {
                        id: 'source',
                        value: breedingValue.sourceCompany ? this.formatEmptyToDash(this.$t(`details.source.${breedingValue.sourceCompany}`)) : '-',
                        horizontalAlignment: "center",
                    },
                ],
            }))
        },

        mapFooterRowLactation(list: LactationInfo[]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const completeBreedingValueLactation: Array<any> = [{
                className: "semi-bold",
                colspan: 1,
                id: "label",
                value: this.$t('details.production.Total') as string
            }];

            const footerRow = list?.find(l => l.id == "overall") as LactationInfo;
            for (let index = 0; footerRow?.breedingValueList?.length > index; index++) {
                const breedingValue = footerRow?.breedingValueList[index];
                completeBreedingValueLactation.push(breedingValue)
            }
        
            // Aaarg?!
            const cellIndexPercentageFat = 2;
            const cellIndexPercentageLactose = 4;
            const cellIndexUrea = 9;
            const decimalsTwo = 2;
            const decimalsOne = 1;
            const decimalsZero = 0;

            const result = [{
            id: "footer-row-1",
            cells: completeBreedingValueLactation.map((breedingValueLactation, index: number) => {
                const sourceCompany = breedingValueLactation?.sourceCompany
                return {
                    className: "semi-bold",
                    horizontalAlignment: "center",
                    id: "number-of-rows",
                    sourceCompany: sourceCompany,
                    value: index >= 12 || index <= 1
                        ? breedingValueLactation.value === 0
                            ? 0
                            : formatValueOrDash(breedingValueLactation.value)
                        : displayDecimals( // Ye be warned, nonsense is afoot
                            breedingValueLactation?.value === null ? "-" : breedingValueLactation.value.toString(),
                            index >= cellIndexPercentageFat && index <= cellIndexPercentageLactose ? decimalsTwo
                            : index === cellIndexUrea ? decimalsOne
                            : decimalsZero,
                            this.$i18n.locale
                        )
                    };
                })
            }];

          return result
        },

        mapDaughterInfoRows(list: DaughterInfo[]) {
            if (list.length === 0) {
                list.push({
                    countryCode: undefined,
                    numberOfDaughters: undefined,
                    numberOfHerds: undefined,
                });
            }
            return list.map((daughterInfo: DaughterInfo) => ({
                id: daughterInfo.countryCode || 'no-data',
                cells: [
                    {
                        id: 'country',
                        value: formatValueOrDash(daughterInfo.countryCode),
                        className: 'text-left',
                    },
                    {
                        id: 'daughters',
                        value: formatValueOrDash(daughterInfo.numberOfDaughters),
                        className: 'text-right',
                    },
                    {
                        id: 'companies',
                        value: formatValueOrDash(daughterInfo.numberOfHerds),
                        className: 'text-right',
                    },
                ],
            }));
        },
    }
});
</script>

<style lang="scss" scoped>
@import '../scss/breeding.scss';
</style>