<template>
    <v-table density="compact">
        <thead>
            <tr>
                <th 
                    v-for="item in headerRows[0].cells" 
                    :key="item.id" 
                    :class="setAlignment(item)"
                    class="fixed-width"
                >
                    {{ item.value }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in bodyRows" :key="row.id">
                <td 
                    v-for="cell in  row.cells"
                    :key="cell.id"
                    :class="setAlignment(cell)"
                    class="fixed-width"
                    >
                    <span v-if="cell.id === 'additionalValue'" class="custom-cell-additionalValue text-end">{{ formatNumber(cell.value) }}</span>
                    <span v-else class="text-end" :class="{ 'measurement-value': cell.id === 'measurement' }">
                        {{ formatNumber(cell.value) }}
                    </span>
                </td>
            </tr>
        </tbody>
    </v-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { formatNumber } from '../../scripts/format-number'
import { translateDaysMixin } from '../../mixins/translate-days';

export default defineComponent({
    name: 'CustomTableView',
    mixins: [translateDaysMixin],
    props: {
        bodyRows: {
            type: Array,
        },
        headerRows: {
            type: Array,
        }
    },
    computed: {
    },
    methods: {
        setAlignment(value) {
            return value.alignment === 'text-left' ? 'text-left' : 'text-end'
        },
        formatNumber(value: string) {
            return formatNumber(value)
        }
    }
});
</script>

<style lang="scss" scoped>
@import '../../scss/detail.scss';

.fixed-width {
  width: 33%;
}
</style>
